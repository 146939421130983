import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-folder-picker.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	source : Object, // cloud storage or root cloud folder
};

@Component({
	template,
	props,
})
export class CloudFolderPicker extends Vue {
	data() {
		return {
			list : [],
		};
	}

	@Async(null, ['source', 'path'])
	get folders() {
		if (!this.source) return null;

		const { cloudStorage_id, cloudFolder_id } = this.source;
		const path = this.path;

		return api.folder.list(Object.assign({ path }, cloudFolder_id
			? { cloudFolder_id }
			: { cloudStorage_id }));
	}

	get path() {
		return this.list.map(({ name }) => name).join('/');
	}

	changeDirectory(folder) {
		if (folder) this.list.push(folder);

		else this.list.pop();
	}

	select() {
		return this.$emit('select', this.path);
	}
}

Vue.component('cloud-folder-picker', CloudFolderPicker);
