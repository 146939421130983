import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import analytics from '@/services/analytics.service';
import api from '@/api';
import flyoverService from '@/services/flyover.service';
import localStorage from '@/services/local-storage.service';
import modalService from '@/services/modal.service';
import routeService from '@/services/route.service';
import sentry from '@/services/sentry.service';
import sessionStorage from '@/services/session-storage.service';
import { isDemoUser } from '@/services/permission.service';
import branding from '@/config/brand';

import acceptInvitation from './accept-invitation/accept-invitation.route';
import declineInvitation from './decline-invitation/decline-invitation.route';
import forgotPassword from './forgot-password/forgot-password.route';
import login from './login/login.route';
import logout from './logout/logout.route';
import onboarding from './onboarding/onboarding.route';
import registration from './registration/registration.route';
import session from './session/session.route';
import test from './test/test.route';
import unsubscribe from './unsubscribe/unsubscribe.route';

const routes = [
	unsubscribe,
	acceptInvitation,
	declineInvitation,
	forgotPassword,
	login,
	logout,
	onboarding,
	registration,
	session,
	test,
	{
		path     : '*',
		redirect : to => ({
			name   : 'login',
			params : {
				redirect : to.path === '/',
			},
		}),
	},
];

const router = new VueRouter({
	routes,
	mode : 'history',
});

export default router;

router.beforeEach((to, from, next) => checkRouteStatus(to, from, next));
router.beforeEach((to, from, next) => checkSession(to, from, next));
router.beforeEach((to, from, next) => isDemoAllowed(to, from, next));

router.afterEach((to, from) => trackPage(to, from));
router.afterEach(() => flyoverService.clear());
router.afterEach(to => setTitle(to));

window.addEventListener('focus', () => {
	gotoLoginIfUserHasLoggedInElsewhere();
});


function setTitle(to) {
	const routeTitle = getRouteTitle(to);
	const brandName = branding.BRAND_NAME || 'Montage';

	document.title = routeTitle ? `${routeTitle} | ${brandName}` : brandName;
}

function checkSession(to, from, next) {
	return validateSession(to)
		.catch(() => null)
		.then(isValid => isValid ? next() : next({ name : 'login' }));
}

function isDemoAllowed(to, from, next) {
	return Promise.resolve(isDemoUser())
		.catch(() => false)
		.then(isDemo => isDemo && to.matched.find(({ meta : { demo } }) => demo === false))
		.then(isDemo => isDemo
			? next({ name : 'login' })
			: next());
}

function checkRouteStatus(to, from, next) {
	return Promise.all([
		from.meta.secure,
		routeService.isLocked(),
	])
		.then(statuses => statuses.every(status => status))
		.then(needsPrompt => needsPrompt && promptForPendingChanges())
		.then(() => next())
		.catch(() => next(false));
}

function promptForPendingChanges() {
	return modalService.launchVerification({
		modal_id      : 'navigation-verification',
		title         : 'Are you sure?',
		primaryButton : {
			label : 'Okay',
			type  : 'is-danger',
		},
		content : 'You will lose any unsaved changes if you navigate away.',
	})
		.then(() => routeService.clear());
}

function trackPage(to) {
	try {
		analytics.trackPage({
			path  : to.fullPath,
			title : getRouteTitle(to),
		});
	}

	catch (error) {
		sentry.captureException(error);
	}
}

function getRouteTitle(route) {
	if (route.meta.title === false) return false;

	const routeWithTitle = route.matched.slice()
		.reverse()
		.find(route => route.meta && route.meta.title);

	return routeWithTitle.meta.title;
}

function validateSession(route) {
	return Promise.resolve(hasValidSession(route));
}

function hasValidSession(route) {
	return !route.meta.secure || api.session.get();
}

function gotoLoginIfUserHasLoggedInElsewhere() {
	const localUser = localStorage.get('user');
	const sessionUser = sessionStorage.get('user');

	if (!localUser) return;

	if (!sessionUser)
		return sessionStorage.set('user', localUser);

	if (localUser.user_id !== sessionUser.user_id)
		router.push({ name : 'login' });
}
