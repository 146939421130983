import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-storage-details.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	value : Object, // cloud storage record
};

@Component({
	template,
	props,
})
export class CloudStorageDetails extends Vue {
	data() {
		return {
			error : null,
		};
	}

	@Async
	get list() {
		return api.cloudFolder.list(this.value);
	}

	deleteFolder({ cloudFolder_id }) {
		const index = this.list.findIndex(item => item.cloudFolder_id === cloudFolder_id);

		if (index === -1) return;

		const found = this.list.splice(index, 1).pop();

		return api.cloudFolder.delete(cloudFolder_id)
			.catch(error => {
				this.error = error;

				this.list.splice(index, 0, found);
			});
	}

	connect(path) {
		const { cloudStorage_id } = this.value;

		return api.cloudFolder.create({
			cloudStorage_id,
			path,
		})
			.catch(error => { this.error = error });
	}
}

Vue.component('cloud-storage-details', CloudStorageDetails);
