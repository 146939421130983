import component from './registration';
import branding from '@/config/brand';

export default {
	component,
	path : '/registration',
	name : 'registration',
	meta : {
		title    : `Register with ${branding.BRAND_NAME}`,
		disabled : process.env.DISABLE_OPEN_REGISTRATION === 'true',
	},
};
